"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientApiError = exports.ClientApiAction = void 0;
var ClientApiAction;
(function (ClientApiAction) {
    ClientApiAction["Closing"] = "closing";
    ClientApiAction["Command"] = "command";
    ClientApiAction["Consent"] = "consent";
    ClientApiAction["Event"] = "event";
    ClientApiAction["Get"] = "get";
    ClientApiAction["Handshake"] = "handshake";
    ClientApiAction["Ready"] = "ready";
    ClientApiAction["Set"] = "set";
})(ClientApiAction = exports.ClientApiAction || (exports.ClientApiAction = {}));
var ClientApiError;
(function (ClientApiError) {
    ClientApiError["GuidInUse"] = "GUID_IN_USE";
    ClientApiError["NoIframe"] = "NO_IFRAME";
    ClientApiError["Timeout"] = "TIME_OUT";
})(ClientApiError = exports.ClientApiError || (exports.ClientApiError = {}));
