"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientApiService = void 0;
var guid_service_1 = require("@qumu-cloud/service-guid/src/guid.service");
var client_api_enum_1 = require("../../enums/client-api.enum");
var guidService = new guid_service_1.GuidService();
var ClientApiService = /** @class */ (function () {
    function ClientApiService(iframeName, options) {
        if (options === void 0) { options = {}; }
        this.iframeName = iframeName;
        this.callbackId = 0;
        this.callbacks = {};
        this.isLoaded = false;
        this.guid = guidService.generate();
        this.origin = options.origin || '*';
        this.timeout = options.timeout || 20000;
        var match = /qcIframeName=(.+?)(?:&|$)/i.exec(ClientApiService.window.location.search);
        this.parentIframeName = match ? match[1] : null;
    }
    /**
     * A piece of code marking an iframe as loaded on receiving a ready message.
     * This is needed for the API initialization to cater for the case where an iframe is already loaded and we can't use the load event.
     * This is fairly optimistic and will likely not work with redirects, though.
     */
    ClientApiService.monitorReadyFrames = function () {
        ClientApiService.window.addEventListener('message', function (event) {
            var message;
            try {
                message = JSON.parse(event.data);
            }
            catch (e) {
                return;
            }
            if (message && message.action === client_api_enum_1.ClientApiAction.Ready) {
                var frame = ClientApiService.window.document.querySelector("iframe[src=\"" + message.value + "\"]");
                // no frame could mean it was redirected
                if (frame) {
                    // set data-loaded to 1 so the client API can identify such an iframe as a ready one and immediately start the handshake
                    frame.dataset.loaded = '1';
                }
            }
        });
    };
    /**
     * Restores the old Client API to the global QC.Api giving a possibility for both the APIs to work along each other.
     */
    ClientApiService.noConflict = function () {
        ClientApiService.window.KV.Api = ClientApiService.oldApi;
        ClientApiService.window.KV.Api2 = ClientApiService;
        return ClientApiService;
    };
    /**
     * Sets a reference to the old Client API
     */
    ClientApiService.setOldApi = function (Api) {
        ClientApiService.oldApi = Api;
    };
    /**
     * Sets the window object to be used in the ClientApiService instances
     */
    ClientApiService.setWindow = function (window) {
        ClientApiService.window = window;
    };
    /**
     * Listen to a player event.
     * Supported events: play, pause, timeupdate, volumechange, ended, liveState.
     */
    ClientApiService.prototype.bind = function (name, callback) {
        this.callbacks[name] = this.callbacks[name] || [];
        this.callbacks[name].push(callback);
    };
    /**
     * Execute a player command.
     * You can specify a callback to be called once the command gets executed.
     * Supported commands: play, pause, destroy.
     */
    ClientApiService.prototype.command = function (name, value, callback) {
        var message = {
            action: client_api_enum_1.ClientApiAction.Command,
            name: name,
        };
        var func = callback;
        if (!callback && typeof value === 'function') {
            func = value;
        }
        if (typeof value !== 'undefined' && typeof value !== 'function') {
            message.value = value;
        }
        if (func) {
            message.callbackId = this.callbackId;
            this.callbacks[this.callbackId] = func;
            this.callbackId += 1;
        }
        this.send(message);
    };
    ClientApiService.prototype.destroy = function () {
        this.callbacks = {};
        this.callbackId = 0;
        ClientApiService.window.removeEventListener('message', this.messageHandler);
    };
    /**
     * Get a player property.
     * The callback will be called with the value returned from the player.
     * Supported properties:
     * - public: currentTime, duration, liveEndTime, liveStartTime, liveState, volume, paused, presentation.
     * - private: ccGuid, ccTrack.
     */
    ClientApiService.prototype.get = function (name, callback) {
        this.callbacks[this.callbackId] = callback;
        this.send({
            action: client_api_enum_1.ClientApiAction.Get,
            callbackId: this.callbackId,
            name: name,
        });
        this.callbackId += 1;
    };
    /**
     * Get the player iframe's name
     */
    ClientApiService.prototype.iframe = function () {
        return this.iframeName;
    };
    /**
     * Initialize the API.
     * Calls the provided callback once complete, passing the initialization error in the first argument (if any).
     */
    ClientApiService.prototype.init = function (callback) {
        var _this = this;
        if (this.iframeName === null) {
            this.iframeWindow = ClientApiService.window.parent;
            this.onLoad();
        }
        else {
            var frameEl = document.querySelector("[name=\"" + this.iframeName + "\"]");
            if (!frameEl) {
                callback(client_api_enum_1.ClientApiError.NoIframe);
                return;
            }
            this.iframeWindow = frameEl.contentWindow;
            if (frameEl.dataset.loaded === '1') {
                this.onLoad();
            }
            else {
                frameEl.addEventListener('load', function () { return _this.onLoad(); });
            }
        }
        this.initCallback = callback;
        this.messageHandler = function (event) { return _this.onMessage(event); };
        ClientApiService.window.addEventListener('message', this.messageHandler);
        this.timeoutId = setTimeout(function () {
            ClientApiService.window.removeEventListener('message', _this.messageHandler);
            clearInterval(_this.handshakeInterval);
            _this.initCallback(client_api_enum_1.ClientApiError.Timeout);
            _this.initCallback = null;
        }, this.timeout);
    };
    /**
     * Set a player property value.
     * Currently supported properties: currentTime, volume.
     */
    ClientApiService.prototype.set = function (name, value) {
        this.send({
            action: client_api_enum_1.ClientApiAction.Set,
            name: name,
            value: value,
        });
    };
    /**
     * Stop listening to a player event
     */
    ClientApiService.prototype.unbind = function (name, callback) {
        if (!this.callbacks[name]) {
            return;
        }
        this.callbacks[name] = callback
            ? this.callbacks[name].filter(function (cb) { return cb !== callback; })
            : [];
    };
    ClientApiService.prototype.onLoad = function () {
        var _this = this;
        // don't send the handshake on subsequent loads e.g. caused by disclaimer redirects
        if (this.isLoaded) {
            return;
        }
        this.isLoaded = true;
        var message = {
            action: client_api_enum_1.ClientApiAction.Handshake,
        };
        // notify the player we're inside an iframe with the given name
        if (this.parentIframeName) {
            message.name = this.parentIframeName;
        }
        this.send(message);
        this.handshakeInterval = setInterval(function () { return _this.send(message); }, 1000);
    };
    ClientApiService.prototype.onMessage = function (event) {
        // ignore message coming from unknown origins when the origin is defined
        if (this.origin !== '*' && event.origin !== this.origin) {
            return;
        }
        var message;
        try {
            message = JSON.parse(event.data);
        }
        catch (e) {
            return;
        }
        // ignore invalid messages and messages for other users
        if (!message || !message.action
            || (message.action === client_api_enum_1.ClientApiAction.Event && (!message.guids || message.guids.indexOf(this.guid) === -1))
            || (message.action === client_api_enum_1.ClientApiAction.Ready && this.isLoaded)
            || ((message.action !== client_api_enum_1.ClientApiAction.Event && message.action !== client_api_enum_1.ClientApiAction.Ready) && message.guid !== this.guid)) {
            return;
        }
        switch (message.action) {
            case client_api_enum_1.ClientApiAction.Command:
                this.callbacks[message.callbackId]();
                delete this.callbacks[message.callbackId];
                break;
            case client_api_enum_1.ClientApiAction.Event:
                {
                    var callbacks = this.callbacks[message.name];
                    if (callbacks) {
                        callbacks.forEach(function (callback) { return callback(message.value); });
                    }
                }
                break;
            case client_api_enum_1.ClientApiAction.Get:
                this.callbacks[message.callbackId](message.value);
                delete this.callbacks[message.callbackId];
                break;
            case client_api_enum_1.ClientApiAction.Handshake:
                // complete the handshake
                if (message.status === 'error') {
                    this.initCallback(message.code);
                }
                else {
                    this.initCallback();
                }
                this.initCallback = null;
                clearInterval(this.handshakeInterval);
                clearTimeout(this.timeoutId);
                break;
            case client_api_enum_1.ClientApiAction.Ready:
                {
                    var readyFrameEl = document.querySelector("iframe[src=\"" + message.value + "\"]");
                    var currentFrameEl = document.querySelector("[name=\"" + this.iframeName + "\"]");
                    // receiving a Ready action from the target frame means we still haven't properly completed the handshake
                    if (readyFrameEl && readyFrameEl === currentFrameEl && readyFrameEl.dataset.loaded === '1') {
                        this.onLoad();
                    }
                }
                break;
        }
    };
    ClientApiService.prototype.send = function (message) {
        var postMessage = __assign(__assign({}, message), { guid: this.guid, version: ClientApiService.version });
        this.iframeWindow.postMessage(JSON.stringify(postMessage), this.origin);
    };
    ClientApiService.version = 2;
    return ClientApiService;
}());
exports.ClientApiService = ClientApiService;
