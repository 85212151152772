"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var client_api_service_1 = require("./services/client-api/client-api.service");
// copy and augment the old KV namespace with the new API
window.KV = window.KV || {};
// assign the new API after making sure we haven't done it yet and noConflict() wasn't called in the meantime
if ((!window.KV.Api || window.KV.Api.version < client_api_service_1.ClientApiService.version)
    && typeof window.KV.Api2 === 'undefined') {
    client_api_service_1.ClientApiService.setOldApi(window.KV.Api);
    window.KV.Api = client_api_service_1.ClientApiService;
    client_api_service_1.ClientApiService.setWindow(window);
    client_api_service_1.ClientApiService.monitorReadyFrames();
}
// eslint-disable-next-line no-console
console.warn('You are using the legacy version of the Qumu Player SDK. Please move to our new library, https://qumu.github.io/player-sdk/legacy/migration/.');
