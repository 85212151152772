"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuidService = void 0;
/**
 * Guid generation methods methods from the Guru Framework
 */
var GuidService = /** @class */ (function () {
    function GuidService() {
        this.URL_SAFE_BASE_62_ALPHABET = 'abcdefghijklmnopqrstuvwxyzABDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        this.NUM_RANDOM_CHARS = 11;
    }
    GuidService.prototype.generate = function () {
        var guid = '';
        for (var n = 0; n < this.NUM_RANDOM_CHARS; n++) {
            guid += this.URL_SAFE_BASE_62_ALPHABET.charAt(Math.floor(Math.random() * this.URL_SAFE_BASE_62_ALPHABET.length));
        }
        return guid;
    };
    GuidService.prototype.generateLong = function () {
        return this.generateShort() + this.generateShort() + "-" + this.generateShort() + "-" + this.generateShort() + "-" + this.generateShort() + "-" + this.generateShort() + this.generateShort() + this.generateShort();
    };
    GuidService.prototype.generateShort = function () {
        // eslint-disable-next-line no-bitwise
        return (((Math.random() + 1) * 0x10000) | 0).toString(16).substring(1);
    };
    return GuidService;
}());
exports.GuidService = GuidService;
exports.default = new GuidService();
